@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
.main
{
    display: flex;
    height: 100vh;
    width: 100%;
}
.sec1
{
    width: 50%;
    height: 100vh;
   
}
.sec1>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.sec2
{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.acc
{
    margin-top: auto;
    width: 1rem;
}
.entry
{
    width: 60%;
}
.entry>input
{
    width: 100%;
    height: 2.5rem;
    border: 1px solid #7E440F;
    border-radius: 10px;
    margin-top: 20px;
    padding: 2px 10px;
    font-family: 'Montserrat';

font-weight: 400;
font-size: 1.1rem;
line-height: 30px;
color: rgba(126, 68, 15, 1)
}
.forgot
{
    margin-right: auto;
    margin-left: 20%;
    margin-top: 30px;
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 0.9rem;
line-height: 24px;
text-decoration: underline;
cursor: pointer;
color: #7E440F;

}
.noacc
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 24px;
    text-decoration: underline;
    color: #7E440F;
    margin-top: auto;
    margin-bottom: 50px;
cursor: pointer;
}
.logbtn
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 24px;
    width: 10rem;
    padding: 10px 25px;
    border: transparent;
    color: white;
    background: #BC5E0E;
    border-radius: 20px;
    margin: 15px;
}
.logbtn:hover
{
    background: #e88228;
}
.google
{
    color: black;
    background-color: white;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
   display: flex;
   justify-content: space-around;
   align-items: center;
    padding: 10px 25px;
    border: 2px solid grey;
    margin-top: 20px;
}
.google:hover
{
    background-color: aliceblue;
    cursor: pointer;
}
.google>img{
    width: 0.8rem;
    margin-right: 10px;
}
.signup
{
    margin-top: auto;
    margin-bottom: 60px;
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 2rem;
line-height: 49px;
text-align: center;
color: #C7711E;

}
.entry::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(126, 68, 15, 0.42);
  
  }
  
  .entry::-moz-placeholder
  { color: rgba(126, 68, 15, 0.42);

  }

  @media only screen and (max-width: 900px) {
.sec1
{
    width: 0;
}  
.sec2
{
    width: 100%;
}
.google
{}
}